


























































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  metaInfo: {
    title: 'Inicio',
  },
  name: "Home",
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
    };
  },
  mounted() {
    this.reloadUser();

    // this.$root.$on('user:logout', () => {
    //   this.$destroy();
    // })
  },
  methods: {
    ...mapActions("auth", ["reloadUser"]),
    openSales() {
      this.$gtag?.event('home', { method: 'click', description: 'click on play button' })
      this.$router.push("/sales");
    },
  },
});
